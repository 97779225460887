@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-SemiBold.eot');
  src: url('/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Medium.eot');
  src: url('/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Regular.eot');
  src: url('/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Light.eot');
  src: url('/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Light.woff2') format('woff2'),
    url('/fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-ExtraLight.eot');
  src: url('/fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
    url('/fonts/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-SemiBold.eot');
  src: url('/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Medium.eot');
  src: url('/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Regular.eot');
  src: url('/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Light.eot');
  src: url('/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-Light.woff2') format('woff2'),
    url('/fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-ExtraLight.eot');
  src: url('/fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
    url('/fonts/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.dashboard-iframe {
  width: -webkit-fill-available;
  height: 100vh;
}

.search-type-select option {
  background-color: white;
  color: #000;
}

.search-type-select .css-13cymwt-control {
  background: transparent;
  border: none;
}

.search-type-select #react-select-2-placeholder {
  color: #fff;
}

.search-type-select .css-1hb7zxy-IndicatorsContainer>span {
  display: none;
}

.search-type-select .css-1xc3v61-indicatorContainer {
  color: #fff;
}

.search-type-select .css-1nmdiq5-menu,
.search-type-select #react-select-2-listbox {
  color: #000;
  width: 200px !important;
  text-align: left;
}

.searchkit-delivery-menu-item .searchkit-filter-menu-item-checked:checked {
  left: 0px;
  top: -1px;
}

.searchkit-filter-menu-item span::before {
  left: -4px;
  top: 1px;
  width: 20px;
  height: 20px;
  position: absolute;
  content: '';
}

.searchkit-filter-menu-item-checked:checked {
  left: -4px;
  top: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 9;
  content: '';
}

.searchkit-radio {
  position: relative;
  left: 2px;
  top: 1px;
}

.searchkit-radio {
  width: 1px;
  height: 1px;
}

button:disabled {
  opacity: 0.5;
}

.planner-cart-gallery-box {
  width: 316px;
  height: 196px;
}

.planner-cart-gallery-box div {
  display: none;
}

.planner-cart-gallery-box:hover div {
  display: flex;
}

input.searchkit-searchbox-header {
  padding-left: 10px;
}

.rounded-r-full {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.searchkit-product-list li {
  padding: 0;
  box-shadow: none;
  border: none;
}

div.searchkit-pagination li.searchkit-pagination-selected-link>a {
  border-radius: 4px !important;
  border: none;
  color: #fff;
  background: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
  width: 24px;
  height: 28px;
  box-shadow: none;
}

.searchkit-pagination .pageItem a:hover,
.searchkit-pagination-next-page a:hover,
.searchkit-pagination-previous-page a:hover {
  background: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%) !important;
  color: #fff;
}

li a.searchkit-pagination-link {
  border-radius: 4px !important;
}

.searchkit-pagination-link {
  border: none;
  background: transparent;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
}

.searchkit-pagination-next-page,
.searchkit-pagination-previous-page {
  border: 1px solid #ededed;
}

.searchkit-pagination-next-page,
.searchkit-pagination-previous-page {
  display: flex;
  height: 30px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #fff;
  font-weight: 500;
}

.searchkit-pagination ul li:last-child>a.searchkit-pagination-link,
.searchkit-pagination ul li:first-child>a.searchkit-pagination-link,
.searchkit-pagination ul li:first-child>span.searchkit-pagination-link {
  border: none;
  text-align: center;
  margin-left: 0 !important;
  display: flex;
  box-shadow: none;
}

.ais-Pagination-link {
  height: 32px !important;
  border: 1px solid #ededed !important;
}

.searchkit-pagination .pageItem a {
  width: 24px;
  height: 32px;
}

.searchkit-pagination-link:active {
  box-shadow: none;
  border: none;
}

.searchkit-pagination-previous-page .searchkit-pagination-link,
.searchkit-pagination-next-page .searchkit-pagination-link {
  background: #fff !important;
  color: #ea4842;
}

.searchkit-search-box-event-type {
  width: 18rem;
  padding-right: 10px;
}

.searchkit-search-box-event-type {
  overflow-x: hidden;
}

.ais-ToggleRefinement-count,
.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RatingMenu-count,
.ais-RefinementList-count {
  background-color: #ea4842 !important;
  color: #fff !important;
  border: none !important;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox,
.ais-GeoSearch-input:checked {
  color: transparent !important;
}

.searchkit-filter-container div.searchkit-clear-all-button-container button.searchkit-clear-all-button {
  background: transparent;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 12px;
  color: #ea4842;
  padding: 0px;
  height: auto;
}

div.searchkit-clear-all-button-container button.searchkit-clear-all-button-mobile,
div.searchkit-clear-all-button-container button.searchkit-clear-all-button-mobile:disabled {
  background-image: linear-gradient(90deg,
      #f38023 -90.01%,
      #dd579d 205.57%,
      #ee3d24 467.37%);
  padding: 4px 12px;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  text-wrap: nowrap;
  font-size: 14px;
  color: #fcfcfc;
  font-weight: 500;
}

div.searchkit-clear-all-button-container button.searchkit-clear-button-mobile,
div.searchkit-clear-all-button-container button.searchkit-clear-button-mobile:disabled {
  background: none;
  color: #ea4842;
  border: none;
  font-weight: 500;
  font-size: 14px;
}

button.searchkit-filter-show-more-button {
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background: linear-gradient(90deg,
      #f38023 -90.01%,
      #dd579d 205.57%,
      #ee3d24 467.37%);
  height: 24px;
  padding: 4px 12px;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  border: none;
}

button.searchkit-filter-show-more-button:hover,
button.searchkit-filter-show-more-button:focus {
  background: linear-gradient(90deg,
      #f38023 -90.01%,
      #dd579d 205.57%,
      #ee3d24 467.37%);
  border: none;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
}

li.searchkit-selected-filters {
  background-color: #fff;
  border: 0.1px solid #ea4842;
  border-radius: 4px;
}

button.searchkit-filter-delete-icon {
  border: 1px solid #bababa;
  border-radius: 100%;
  width: 16px;
  height: 16px;
}

.searchkit-range-selector .rc-slider-rail {
  height: 8px;
}

.searchkit-range-selector .rc-slider-track {
  background-image: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
  height: 8px;
}

.searchkit-range-selector .rc-slider-handle {
  border: 1px solid #e58023;
  width: 20px;
  height: 20px;
  background-color: #fff;
  opacity: 1;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-backdrop {
  z-index: 1;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.open-more-detail-product-btn {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.product-more-detail-btn {
  box-shadow: 2px 2px 8px 0px #0000001a, 2px 2px 2px 0px #00000040 inset,
    -2px -2px 2px 0px #00000026;
}

.product-more-detail-btn:hover {
  background-color: #fff !important;
  background-image: none;
  border: 1px solid #e03d24;
}

.statistics-card {
  box-shadow: 2px 2px 4px 0px #ea48421a, 1px 1px 2px 0px #ea484240 inset,
    -1px -1px 2px 0px #ea484226;
}

select.searchkit-sortby-selected-item {
  background: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
  background-position: right 0rem center, 0 0;
  background-repeat: no-repeat;
  border: none;
  box-shadow: none;
  color: #fff;
  appearance: auto;
}

select.searchkit-sortby-selected-item,
select.searchkit-sortby-selected-item:hover,
select.searchkit-sortby-selected-item:focus,
select.searchkit-sortby-selected-item option,
select.searchkit-sortby-selected-item option:hover,
select.searchkit-sortby-selected-item option:checked {
  background: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
  color: #fff;
}

select.searchkit-sortby-selected-item option:checked {
  background: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
  color: #fff;
}

li.searchkit-selected-item>a>span:first-child {
  color: #e03d24 !important;
  font-weight: 500 !important;
}

.page-header {
  box-shadow: 0px -1px 0px 0px #0000000d inset;
  transition: top 0.2s ease-in-out;
}

.searchkit-home-submit-button {
  margin-left: -6.2rem;
}

.image-container img {
  opacity: 0;
}

.image-container {
  position: relative;

  img {
    position: absolute;
    animation-name: multiple-image-crossfade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 8s;

    &:nth-of-type(1) {
      animation-delay: 6s;
    }

    &:nth-of-type(2) {
      animation-delay: 4s;
    }

    &:nth-of-type(3) {
      animation-delay: 2s;
    }

    &:nth-of-type(4) {
      animation-delay: 0;
    }
  }
}

@keyframes multiple-image-crossfade {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 1500px) and (max-width: 3000px) {
  .product-search-query {
    padding-right: 0;
  }
}

.product-list-wrapper .search-type-select,
.product-list-wrapper .searchkit-searchbox-header {
  height: 3rem;
}

.product-list-wrapper .searchkit-home-submit-button {
  margin-top: 4px;
  margin-left: -7.3rem;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ais-SearchBox-reset {
  right: 9rem !important;
  top: 55% !important;
}

.h-5\/6 {
  height: 80%;
}

.w-9\/12 {
  width: 75%;
}

.w-9\/10 {
  width: 80%;
}

.z-101 {
  z-index: 101;
}

.top-2\/4 {
  top: 50%;
}

.h-full {
  height: 100%;
}

.bottom-10 {
  bottom: 2.5rem;
}

.fixed {
  position: fixed;
}

.top-8 {
  top: 2rem;
}

.angle-icon-white {
  display: none;
}

.sort-by:hover .angle-icon-red {
  display: none;
}

.sort-by:hover .angle-icon-white {
  display: block;
}

.sort-icon-white {
  display: none;
}

.sort-by:hover .sort-icon-red {
  display: none;
}

.sort-by:hover .sort-icon-white {
  display: block;
}

.black-overlayer {
  background: #000000b0;
}

.filter-scroll::-webkit-scrollbar {
  display: none;
}

.filter-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.flip-card {
  background-color: transparent;
  width: 320px;
  height: 400px;
  perspective: 5000px;
  transition: width 1ms, height 1ms;
}

.searchkit-product-item:hover .add-to-cart-btn {
  opacity: 1;
  height: auto;
  transition: opacity 1ms ease-in;
}

.searchkit-product-item:hover .flip-card {
  height: 430px;
  transition: opacity 1ms ease-in;
}

.add-to-cart-btn {
  transition: opacity 1ms ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1s;
}

.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fade-in {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut 2s;
  visibility: hidden;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animation-container {
  height: 30px;
  overflow: hidden;
  width: 10rem;
  position: relative;
}

.animation-container #animation-text-1 {
  animation-delay: 0ms;
}

.animation-container #animation-text-2 {
  animation-delay: 1500ms;
}

.animation-container #animation-text-3 {
  animation-delay: 3000ms;
}

.animation-container #animation-text-4 {
  animation-delay: 4500ms;
}

.animation-container #animation-text-5 {
  animation-delay: 6000ms;
}

.animation-container #animation-text-6 {
  animation-delay: 7500ms;
}

.animation-container #animation-text-7 {
  animation-delay: 9000ms;
}

.animation-container #animation-text-8 {
  animation-delay: 10500ms;
}

.animation-container #animation-text-9 {
  animation-delay: 12000ms;
}

.animation-container #animation-text-10 {
  animation-delay: 13500ms;
}

.text-animation {
  position: absolute;
  top: 100%;
  left: 4px;
  animation: 10000ms ease 0s infinite normal forwards running textScrollAnimation;
  font-weight: 500;
  line-height: 18px;
  margin-top: 5px;
}

@keyframes textScrollAnimation {
  0% {
    top: 100%;
    visibility: visible;
  }

  10% {
    top: 0px;
  }

  20% {
    top: -100%;
    visibility: hidden;
  }

  100% {
    top: 100%;
    visibility: hidden;
  }
}

.toaster {
  transform: translateX(-50%);
}

.bg-info-light {
  background-color: #e5f5fc;
}

.text-info-base {
  background-color: #017aad;
}

.datepicker-input {
  padding-left: 2.5rem !important;
}

.planner-cart-gallery-box {
  width: 316px;
  height: 196px;
}

.planner-cart-gallery-box div {
  display: none;
}

.planner-cart-gallery-box:hover div {
  display: flex;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.credit-toggle {
  background: #fcfcfc54;
}

.credit-toggle:before {
  background: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
}

input:checked+.credit-toggle {
  background-color: white !important;
}

.toggle-slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.toggle-slider {
  background: #ea484282;
}

input:focus+.toggle-slider {
  box-shadow: 0 0 1px #ea4842;
}

input:checked+.toggle-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.toggle-slider.round {
  border-radius: 34px;
}

.toggle-slider.round:before {
  border-radius: 50%;
}

.rich-text-editor div:nth-child(2) {
  overflow: auto;
  padding-top: 0.5rem;
}

.rich-text-editor ol,
.rich-text-editor ul {
  padding: 2px 20px;
}

.reduce-gap {
  gap: 0px !important;
}

.planner-cart-title-bg {
  background: #fdf1ed;
}

.text-info-base {
  color: #017aad;
}

.bg-info-light {
  background: #e5f5fc;
}

.bg-dim-gray {
  background-color: #717171;
}

.bg-green {
  background-color: #009518;
}

.bg-error-red {
  background-color: #ff3200;
}

.new {
  color: #017aad;
  background: #e5f5fc;
}

.in-progress {
  color: #e99400;
  background: #fdf4e5;
}

.submitted {
  color: #29823b;
  background: #eaf3eb;
}

.expired {
  color: #dc2020;
  background: #fceaea;
}

.bg-warning-light {
  background-color: #fdf4e5;
}

.text-warning-dark {
  color: #e99400;
}

.order-view .product-image {
  height: 144px;
}

@media (min-width: 320px) and (max-width: 720px) {
  .order-view .product-image {
    height: 94px;
  }

  .action-center-planner-dropdown {
    width: 10rem !important;
    height: 45px;
  }
}

.order-view .product-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.-left-40 {
  left: -10rem;
}

.disable-bg {
  background: #eaeaea;
}

.multi-select-epm {
  font-size: 0.875rem;
}

.css-3iigni-container {
  pointer-events: none;
  position: relative;
  box-sizing: border-box;
}

.select2-selection .form-select__placeholder {
  font-weight: 500;
  color: #bababa;
}

.event-progress-bar-content {
  background: rgba(252, 252, 252, 0.2);
}

.event-profile-progress-bar>div>div>div {
  background: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%) !important;
  margin: 2px;
  height: 16px !important;
}

.event-profile-progress-bar>div>div {
  background-color: #fff !important;
}

.progress-bar-label {
  white-space: pre-wrap;
  word-break: break-word;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  background: rgba(34, 34, 34, 0.8);
}

@media (min-width: 768px) {
  .md\:max-w-152 {
    max-width: 38rem;
  }

  .md\:-translate-y-1\/2 {
    transform: translateY(-50%);
  }

  .md\:-translate-x-1\/2 {
    transform: translateX(-50%);
  }

  .md\:top-40 {
    top: 10rem;
  }

  .md\:left-1\/2 {
    left: 50%;
  }

  .md\:absolute {
    position: absolute;
  }
}

.font-above-normal {
  font-weight: 450;
}

.m-18 {
  margin-top: 4.5rem;
}

.radio-container {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-w16 .radiomark {
  width: 16px;
  height: 16px;
}

.radio-container.radio-w16 .radiomark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
}

.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 50%;
}

.radio-container input:checked~.radiomark {
  background-color: #ea4842;
}

.radiomark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-container input:checked~.radiomark:after {
  display: block;
}

.radio-container .radiomark:after {
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: white;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #fbeff2 transparent transparent;
}

.book-order-info-message {
  display: none;
}

.book-order-info:hover .book-order-info-message {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.profile-event-header {
  background: #495057;
  padding: 8px;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

.profile-event-header,
.profile-user-header {
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.hamburger-menu-item:hover .group-menu-icon {
  display: none;
}

.hamburger-menu-item:hover .group-menu-icon-hover {
  display: block;
}

.hamburger-menu-item:hover {
  background-color: #f3f3f6;
}

.hover\:opacity-90:hover {
  opacity: 0.9;
}

.welcome-menu:hover>.menu-white-icon {
  display: none;
}

.welcome-menu:hover>.menu-red-icon {
  display: block !important;
}

.expired-event {
  color: #717171;
}

.list-page-search-container .ais-SearchBox-reset {
  right: 2rem !important;
  top: 58% !important;
}

.object-fit-cover {
  object-fit: 'cover';
}

blockquote {
  border-left: none;
  padding-left: 0;
  padding-left: 10px;
  border-left: 2px solid #ddd;
  color: #8c8c8c;
  font-style: italic;
}

.epm-dropdown .css-166bipr-Input,
.epm-dropdown .css-1dimb5e-singleValue,
.epm-dropdown .form-select__single-value,
.epm-dropdown .css-olqui2-singleValue,
.epm-dropdown .border__placeholder,
.epm-dropdown .form-select__placeholder {
  padding-left: 30px;
}

.react-datepicker-popper {
  z-index: 10;
}

.cancelled {
  color: #717171;
}

.cancelled .bg-light-red {
  background: #e4e4e4;
}

.cancelled .text-brand {
  color: #717171;
}

.cancelled-order {
  color: #dc2020 !important;
}

.epm-dropdown .css-166bipr-Input {
  padding-left: 30px;
}

.searchkit-pagination-next-page,
.searchkit-pagination-previous-page {
  border: none;
}

.bg-since {
  background-size: 100% 100%;
}

.phone-code-container .select__control {
  border-left: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  height: 3rem;
  border-color: #e4e4e4;
}

input {
  font-size: 14px;
}

.form-select__menu {
  z-index: 3;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 6px;
  font-size: 36px;
}

.text-brand-gradient {
  background: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.select__menu {
  z-index: 9 !important;
}

.border-opacity-color {
  border-color: rgb(228 228 228 / 35%);
}

.epm-summary-rich-editor .css-1vo3iz0 {
  padding: 6px 26px 9px !important;
}

.phone-code-select .select__menu {
  width: 16rem;
}

.sidebar-menu:hover .menu-white-icon {
  display: none;
}

.sidebar-menu:hover {
  box-shadow: 2px 2px 8px 0px #0000001a, 2px 2px 2px 0px #00000040 inset,
    -2px -2px 2px 0px #00000026;
}

.sidebar-logout-menu:hover {
  box-shadow: none;
}

.sidebar-logout-menu div {
  color: #bababa;
}

.sidebar-logout-menu:hover div {
  color: #ea4842 !important;
}

.sidebar-menu:hover div {
  color: #fff;
}

.sidebar-menu:hover .menu-red-icon {
  display: block !important;
}

.epm-dropdown .form-select__input-container {
  padding-left: 30px;
}

.truncate-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.truncate-text-three-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.truncate-text-one-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media only screen and (min-width: 600px) {
  .sendbird-bot #aichatbot-widget-window {
    width: 98%;
    max-width: 98%;
    height: 100%;
    max-height: 80%;
    right: 1%;
    left: 1%;
  }

  .sendbird-bot #aichatbot-widget-button {
    display: none;
  }

  .sendbird-bot #aichatbot-widget-close-icon {
    display: none;
  }
}

.ais-CurrentRefinements-delete {
  color: #bababa !important;
}

.order-line-item .product-image {
  object-fit: cover;
}

.order-line-item .product-image img {
  height: 142px !important;
}

.rc-slider-handle-2 {
  width: 25px !important;
  height: 25px !important;
  margin-top: -9px !important;
}

.rc-slider-handle-1 {
  margin-top: -7px !important;
}

/* TODO: Viresh - Need to verify this change. */
.ais-SearchBox-submitIcon {
  display: block;
}

.curve-shadow {
  box-shadow: 2px -2px 0px 0px #f3f3f6 inset;
}

.bg-add-host-menu-item {
  background-image: linear-gradient(66.43deg,
      #e5812371 -33.51%,
      #cf578f51 94.65%,
      #e03d2486 207.46%);
}

.ais-SearchBox-form::before {
  top: 70%;
}

.epic-font-style span {
  display: inline-block;
}

.epic-font-style span::first-letter {
  font-weight: bold;
}

.search-input-box::placeholder {
  color: #bababa;
}

input::placeholder {
  color: #bababa !important;
}

.ais-HierarchicalMenu-showMore:active,
.ais-Menu-showMore:active,
.ais-RefinementList-showMore:active,
.ais-ClearRefinements-button:active,
.ais-InfiniteHits-loadMore:active,
.ais-InfiniteHits-loadPrevious:active,
.ais-Pagination-item--selected .ais-Pagination-link,
.ais-Pagination-link:active,
.ais-GeoSearch-redo:active,
.ais-GeoSearch-reset:active,
.ais-VoiceSearch-button:active {
  background-color: none;
  box-shadow: none;
}

.rise-shake {
  animation: jump-shaking 4s infinite;
}

@keyframes jump-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateY(-2px);
  }

  35% {
    transform: translateY(-2px) rotate(10deg);
  }

  55% {
    transform: translateY(-2px) rotate(-10deg);
  }

  65% {
    transform: translateY(-2px) rotate(10deg);
  }

  75% {
    transform: translateY(-2px) rotate(-10deg);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
}

.ai-chat-icon::before {
  content: '';
  display: block;
  background: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
  height: 40px;
  width: 32px;
  transform: translate(0);
  position: absolute;
  animation: rotate 5s linear forwards infinite;
  z-index: 0;
  top: 50%;
  transform-origin: top center;
  border-radius: 8px;
}

.ai-chat-icon .inner {
  margin: 2px;
  border-radius: 5px;
}

.ai-chat-icon .inner {
  position: relative;
  z-index: 1;
  width: 100%;
}

.ai-chat-icon {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: 5px;
  border-bottom-color: #000000;
  border-width: 0 5px 5px;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.active-tab-menu-label,
.tab-menu-label img {
  display: flex !important;
}

.tab-menu li .tab-menu-count,
.searchkit-list-label {
  display: none;
}

.tab-menu li {
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.tab-menu li:hover {
  transform: scaleX(1.1);
  background-color: #fff;
  color: #dc2020;
  border-top: 1px solid #dc2020;
  border-right: 1px solid #dc2020;
  border-left: 1px solid #dc2020;
  border-bottom: 1px solid #dc2020;
}

.tab-menu li:hover .tab-menu-label div {
  display: flex !important;
}

.active-tab-menu-label div {
  display: flex !important;
  color: #fff !important;
}

.credit-entity-dropdown li {
  padding: 10px 12px;
  box-shadow: none;
  border: none;
  font-size: 16px;
  color: #717171;
  font-weight: 500;
}

.credit-entity-dropdown li:last-child {
  margin-bottom: 10px;
}

.bg-white-opacity {
  background: #fcfcfc40;
}

.bg-credit-entity-utilization {
  background: linear-gradient(66.43deg,
      rgba(229, 128, 35, 0.2) -33.51%,
      rgba(207, 87, 143, 0.2) 94.65%,
      rgba(224, 61, 36, 0.2) 207.46%);
}

.scroll::-webkit-scrollbar {
  width: 0px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@media (min-width: 1300px) and (max-width: 1445px) {
  .max-w-lg-20 {
    max-width: 9rem;
  }

  .w-lg-24 {
    width: 28rem !important;
  }
}

.searchkit-icon form::before {
  top: 50%;
}

.searchkit-icon .ais-SearchBox-reset {
  right: 1rem !important;
  top: 53% !important;
}

.homepage-searchbox .ais-SearchBox-reset {
  top: 67% !important;
  right: 117px !important;
}

.list-card-footer {
  display: none;
}

.list-card:hover .list-card-footer {
  display: flex;
}

.fuzzy-toggle input:checked+.toggle-slider {
  background-image: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%) !important;
}

.quick-find-search .ais-SearchBox-reset {
  right: 19rem !important;
  top: 53% !important;
}

.entity-number-highlight mark {
  background-color: #ffeb3b;
  color: black;
}

.quick-find-box:focus-within {
  border: 1px solid red;
}

.banner-overlay-thumbnail .image-gallery-slide img {
  object-fit: contain;
  width: 100%;

  .surface-web & {
    height: 536px;
  }
}

.tooltip {
  z-index: 99999999;
}

.tooltip-arrow {
  display: none;
}

.tooltip::after {
  display: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.add-new-card-info {
  background: linear-gradient(66.43deg,
      rgba(229, 128, 35, 0.1) -33.51%,
      rgba(207, 87, 143, 0.1) 94.65%,
      rgba(224, 61, 36, 0.1) 207.46%);
}

.selected-count {
  background: #fff !important;
  color: #dc2020 !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(66.43deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.searchkit-order-status-filter-menu span::before {
  top: -1px !important;
  left: 0px;
}

.user-event-icon:hover .user-event-white-icon {
  display: block !important;
}

.user-event-icon:hover .user-event-brand-icon {
  display: none !important;
}

.search-icon:hover .search-white-icon {
  display: block !important;
}

.search-icon:hover .search-brand-icon {
  display: none !important;
}

.ai-chat:hover .ai-chat-brand-icon {
  display: none !important;
}

.ai-chat:hover .ai-chat-white-icon {
  display: block !important;
}

.searchkit-searchbox .ais-SearchBox-reset {
  right: 1rem !important;
}

.searchkit-searchbox::before {
  top: 55%;
}

.order-date-picker input {
  height: 32px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  padding-left: 30px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
}

.order-date-picker input:focus-visible {
  border: 1px solid #dc2020 !important;
}

.searchkit-datepicker-container div.searchkit-datepicker-day {
  font-size: 12px;
  font-weight: 600;
  color: #ea4842;
}

.searchkit-datepicker-container div.react-datepicker__day--outside-month {
  color: #bababa;
}

.searchkit-datepicker-container div.react-datepicker__header {
  background: #fff;
  font-size: 12px;
  border-bottom: none;
}

.searchkit-datepicker-container .react-datepicker__triangle {
  display: none !important;
}

.searchkit-datepicker-container div.react-datepicker__current-month,
.searchkit-datepicker-container div.react-datepicker__day-name {
  font-weight: 600;
  padding: 5px 0;
  font-size: 12px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 100%;
  color: #fff;
  background: linear-gradient(66deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
}

.searchkit-datepicker-container div.react-datepicker__day--selected,
.react-datepicker__day--today,
.searchkit-datepicker-container div.searchkit-datepicker-selected-day,
.searchkit-datepicker-container div.react-datepicker__day:hover {
  border-radius: 100%;
  color: #fff;
  background: linear-gradient(66deg,
      #e58023 -33.51%,
      #cf578f 94.65%,
      #e03d24 207.46%);
}

.searchkit-datepicker-container .searchkit-date-picker {
  display: none;
}

.searchkit-datepicker-container .react-datepicker {
  border: none;
  box-shadow: 0px 2px 8px 0px #00000014;
}

.searchkit-datepicker-container .react-datepicker__navigation {
  top: 11px;
}

.searchkit-datepicker-container .react-datepicker__day--disabled {
  color: #ccc !important;
}

.add-new-cart-info {
  background: linear-gradient(66.43deg,
      rgba(229, 128, 35, 0.1) -33.51%,
      rgba(207, 87, 143, 0.1) 94.65%,
      rgba(224, 61, 36, 0.1) 207.46%);
}

.action-center-welcome-menu:hover>.action-center-menu-white-icon {
  display: none;
}

.action-center-welcome-menu:hover>.action-center-menu-red-icon {
  display: block !important;
}

.action-center-planner-dropdown .form-select__control {
  background: transparent;
  border: 1px solid #f3f3f6;
  border-radius: 7px;
  height: 0rem;
  color: #fff;
}

.action-center-planner-dropdown .form-select__single-value {
  color: #fcfcfc;
  font-weight: 400;
}

.action-center-planner-dropdown .form-select__indicator-separator {
  display: none;
}

.action-center-planner-dropdown .form-select__clear-indicator {
  display: none !important;
}

.action-center-planner-dropdown .form-select__input-container {
  color: #fcfcfc;
}

.action-center-planner-dropdown svg {
  color: #fcfcfc;
}

.action-center-planner-dropdown .form-select__control:hover {
  border: 1px solid #f3f3f6;
}

.action-center-planner-dropdown {
  width: 14rem;
}

.expired-event-list .ais-InfiniteHits {
  display: flex;
  width: 100%;
}

.expired-left-nav .ais-Menu-count {
  background: #fff !important;
  color: #ea4842 !important;
}

.ais-SearchBox-loadingIndicator,
.ais-SearchBox-reset {
  color: #d1d1db;
  fill: #d1d1db;
}

.ais-SearchBox-input {
  box-shadow: none;
}

.create-event-bg {
  background: linear-gradient(63.64deg, #f97f9a 8.29%, #fac174 121.2%);
}

.ais-SearchBox-input:focus {
  border: 1px solid #ea4842;
}

.ais-InfiniteHits-loadMore:focus {
  border: none;
}

.order-search-box:focus {
  border: none;
}

.css-1u9des2-indicatorSeparator {
  background-color: hsl(0, 0%, 90%);
}

.create-event-form-picker .react-datepicker {
  left: 30px;
}

.overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #222222cc;
}

.searchkit-sort-overlayer {
  display: none;
}

@media (min-width: 300px) and (max-width: 720px) {
  .searchkit-sort-overlayer {
    display: block;
  }
}

.swipeable-footer-view {
  width: -webkit-fill-available;
}

.card-flex-shrink {
  flex-shrink: inherit;
}
